/* PrivacyPolicy.css */
.privacy-policy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    color: #333;
}

.privacy-policy-container h1,
.privacy-policy-container h2 {
    color: #0056b3;
}

.privacy-policy-container h1 {
    font-size: 24px;
    margin-bottom: 16px;
}

.privacy-policy-container h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-policy-container h3 {
    font-size: 16px;
    color: #004085;
    margin-top: 15px;
}

.privacy-policy-container p,
.privacy-policy-container ul {
    font-size: 14px;
    line-height: 1.6;
    margin-top: 0;
    margin-bottom: 10px;
}

.privacy-policy-container li {
    margin-bottom: 5px;
}

.privacy-policy-container ul {
    padding-left: 20px;
}
